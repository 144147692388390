/* General page styling */
body {
    font-family: Arial, sans-serif;
    background-color: #f4f6f9;
    color: #333;
    margin: 0;
    padding: 20px;
}
.main-content {
    display: flex; /* Use flexbox for layout */
    margin-top: 60px; /* Adjust this value based on the height of your top bar */
}

.top-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background-color: #282c34;
    color: white;
    z-index: 1000;
    height: 70px;
}
.top-bar h1 {
    margin-left: 20px; /* Space between the header and the left side */
}

.settings-icon {
    font-size: 24px; /* Adjust the size of the icon */
    margin-right: 20px; /* Space between the icon and the edge */
    color: white; /* Icon color */
    transition: color 0.3s ease; /* Smooth transition for color */
}
.settings-icon:hover {
    color: #5b67ca; /* Change color on hover */
}

.persona-card:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Shadow on hover */
}

.persona-list-container {
    display: flex;
    flex-direction: column; /* Stack the persona list and question area */
    align-items: center; /* Center the content */
    width: 100%;
}

.persona-list {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(270px, 1fr));
    gap: 15px;
    width: 100%; /* Ensure the grid takes up full width */
}

.landing-persona-card {
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 15px;
    margin: 10px;
    cursor: pointer;
    transition: box-shadow 0.3s;
    width: 270px;
    min-height: 400px; /* Set a minimum height */
    display: flex;
    flex-direction: column;
    background-color: white;
    text-align: center;
    align-items: center;
    justify-content: flex-start; /* Align content at the top */
    overflow: visible; /* Allow the card to expand with content */
}

.persona-card {
    border: 1px solid #ccc; /* Border for the card */
    border-radius: 8px; /* Rounded corners */
    padding: 15px; /* Padding inside the card */
    margin: 10px; /* Space between cards */
    cursor: pointer; /* Change cursor to pointer */
    transition: box-shadow 0.3s; /* Smooth shadow transition */
    width: 270px; /* Set a minimum width for the card */
    height: 400px; /* Set a minimum height for the card */
    display: flex; /* Use flexbox for content alignment */
    flex-direction: column; /* Stack content vertically */
    justify-content: space-between; /* Space out content */
    background-color: white; /* Background color */
    text-align: center; /* Center text horizontally */
    align-items: center;
}
.persona-card.selected {
    background-color: #5b67ca; /* Blue background for selected cards */
    color: white; /* Adjust text color for contrast */
}
.persona-card h3{
    margin: 5px;
}

.persona-image {
    width: 150px; /* Set a fixed width for the image */
    height: auto; /* Maintain aspect ratio */
}

.personas-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px; /* Space between header and persona cards */
}

.personas-buttons {
    display: flex;
    gap: 10px; /* Space between buttons */
}

.chat-icon-container {
    position: relative;
    bottom: 10px;
    left: 45%;
    opacity: 0;
    transition: opacity 0.3s ease;
}

.chat-button, .generate-button {
    padding: 10px 20px;
    font-size: 14px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.chat-button {
    background-color: #5b67ca;
    color: white;
}

.chat-button:hover {
    background-color: #4a57b1;
}

.generate-button {
    background-color: #5b67ca;
    color: white;
}

.generate-button:hover {
    background-color: #4a57b1;
}

.persona-card:hover .chat-icon-container {
    opacity: 1; /* Show the icon on hover */
}
.tooltip {
    visibility: hidden; /* Initially hidden */
    background-color: rgba(0, 0, 0, 0.7); /* Background color */
    color: #fff; /* Text color */
    text-align: center; /* Center text */
    border-radius: 4px; /* Rounded corners */
    padding: 5px; /* Padding */
    position: absolute; /* Position absolutely */
    bottom: 40px; /* Position above the icon */
    right: 10px; /* Align to the right */
    z-index: 10; /* Ensure it appears above other elements */
    transition: visibility 0s, opacity 0.3s ease; /* Smooth transition */
    opacity: 0; /* Initially transparent */
}

.chat-icon-container:hover .tooltip {
    visibility: visible; /* Show tooltip on hover */
    opacity: 1; /* Make it fully visible */
}

.chat-icon {
    font-size: 24px; /* Adjust the size of the icon */
    color: #333; /* Default color */
    transition: color 0.3s ease, transform 0.3s ease; /* Smooth transition for color and transform */
}

.chat-icon:hover {
    color: #5b67ca; /* Change color on hover */
    transform: scale(1.1); /* Slightly increase size on hover */
}

.persona-card:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Shadow on hover */
}

.error-message {
    color: red;
    margin-bottom: 10px;
    font-size: 14px;
}

.sidebar {
    display: flex;
    flex-direction: column;
    width: 200px;
    background-color: white;
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
    position: fixed;
    top: 70px;
    left: 0;
    height: calc(100vh - 70px);
    overflow-y: auto;
}

.sidebar ul {
    list-style-type: none; /* Remove default list styling */
    padding: 0; /* Remove padding */
}

.sidebar li {
    padding: 10px; /* Padding for list items */
    cursor: pointer; /* Change cursor to pointer */
    transition: color 0.3s ease; /* Smooth transition for text color */
}

.sidebar li a {
    text-decoration: none; /* Remove underline from links */
    color: #333; /* Default link color */
}

.sidebar li a:hover {
    color: #5b67ca; /* Hover color for links */
}
  
.sidebar li .selected {
    color: #5b67ca; /* Highlight the active link */
    font-weight: bold; /* Optional: Make the active link bold */
}

.sidebar i {
    margin-right: 10px; /* Space between icon and text */
}
.account-container {
    position: relative; /* Position relative for absolute positioning of the menu */
    margin-top: auto; /* Push the account button to the bottom */
}

.account-button {
    background-color: #5b67ca; /* Button background color */
    color: white; /* Button text color */
    border: none; /* No border */
    padding: 10px; /* Padding for the button */
    border-radius: 4px; /* Rounded corners */
    cursor: pointer; /* Change cursor to pointer */
    width: 100%; /* Set width to 100% to span the sidebar */
    text-align: center; /* Center text */
    margin-top: auto; /* Push the button to the bottom of the sidebar */
}
.account-menu {
    position: absolute; /* Position the menu absolutely */
    bottom: 30px; /* Position above the button */
    left: 50%; /* Center the menu horizontally */
    transform: translateX(-50%); /* Adjust to center the menu */
    background-color: white; /* Background color of the menu */
    border: 1px solid #ccc; /* Border for the menu */
    border-radius: 4px; /* Rounded corners */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Shadow for depth */
    z-index: 100; /* Ensure it appears above other elements */
    width: 150px; /* Set a fixed width for the menu */
}

.account-menu button {
    background: none; /* No background */
    border: none; /* No border */
    padding: 5px; /* Smaller padding */
    width: 100%; /* Full width of the menu */
    text-align: center; /* Center text */
    cursor: pointer; /* Change cursor to pointer */
    color: black; /* Set text color to black */
}

.account-menu button:hover {
    background-color: #f0f0f0; /* Change background on hover */
}
.content-container {
    flex: 1; /* Take the remaining space */
    margin-left: 200px; /* Leave space for the sidebar */
    padding: 20px; /* Padding for the content */
    border-radius: 8px; /* Rounded corners */
}
/* Flexbox layout for the app */
.app-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
}

/* Logout button container */
.logout-container {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 20px;
}

button {
    background-color: #5b67ca;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease;
}

button:hover {
    background-color: #4a57b1;
}



/* Flexbox container for persona list and conversation */
.flex-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 20px;
}

/* Persona list styling */
ul {
    list-style-type: none;
    padding: 0;
}

li {
    margin-bottom: 10px;
}

/* Flex item styling for PersonaList and Conversation */
.flex-container > * {
    flex: 1; /* Each item takes up equal space */
}

/* Persona preview box */
.persona-preview {
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    padding: 15px;
    border-radius: 4px;
    margin-top: 10px;
    font-size: 14px;
    line-height: 1.5;
}
.card-preview {
    display: grid; /* Use CSS Grid for layout */
    grid-template-columns: 1fr 1fr;
    margin: 0; /* Remove default margin */
    padding: 0; /* Remove default padding */
    text-align: center;
  }
  .card-prop-container{
    line-height: 1;
    margin:0;
  }
  .card-prop-container p{
    margin:8px;
  }
  .card-prop-container:last-child{
    line-height: 1;
    margin:0;
    grid-column: 1 / 3;
  }

  
/* Message styling */
.message {
    font-size: 18px;
    color: #888;
}

.conversation-container {
    margin-top: 10px;
    width: 100%;
    text-align: left;
}

.conversation-item {
    background-color: #f1f1f1;
    padding: 10px;
    border-radius: 4px;
    margin-bottom: 5px;
    word-wrap: break-word;
    overflow-wrap: break-word;
    box-sizing: border-box;
}

.large-textarea {
    width: 100%;        /* Make it full-width */
    height: 150px;      /* Increase height for better visibility */
    padding: 10px;      /* Add some padding inside the text area */
    margin-bottom: 15px; /* Add margin below the text area to separate it from the button */
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    box-sizing: border-box;
}

.question-text-area {
    flex-grow: 1; /* Take up remaining space */
}

.question-text-area textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    resize: none; /* Disable manual resizing */
    font-size: 16px;
    box-sizing: border-box;
    min-height: 50px; /* Set a default height */
}

.question-container {
    width: 100%;
    display: flex;
    align-items: center;
    margin-top: 20px;
    padding: 10px;
    box-sizing: border-box;
    border-top: 1px solid #ccc; /* Optional separator */
    gap: 10px;
}

.question-submit-button {
    height: 50px; /* Constant height */
    padding: 0 20px; /* Horizontal padding for button text */
    font-size: 16px;
    background-color: #5b67ca;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    display: flex;
    align-items: center; /* Center text/icon vertically */
    justify-content: center; /* Center text/icon horizontally */
    transition: background-color 0.3s ease;
}

.question-submit-button:hover {
    background-color: #4a57b1;
}

.conversation-submit-button {
    background-color: #5b67ca;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease;
}

.conversation-submit-button:hover {
    background-color: #4a57b1;
}

.switch-button {
    margin-left: 10px; /* Additional spacing from Logout */
}  
